<template>
  <div id="app">
    <!-- Only show the button if the user is not subscribed -->
    <button v-if="!isSubscribed" @click="subscribeToNotifications">Subscribe to Notifications</button>
    <SeekBarDisplay />
  </div>
</template>

<script>
import SeekBarDisplay from './components/SeekBarDisplay.vue';

export default {
  name: 'App',
  data() {
    return {
      isSubscribed: false,  // Track whether the user is subscribed
    };
  },
  components: {
    SeekBarDisplay
  },
  methods: {
    async subscribeToNotifications() {
      try {
        // Register the service worker
        const registration = await navigator.serviceWorker.register('/service-worker.js');
        console.log('Service Worker registered with scope:', registration.scope);

        // Request permission to send notifications
        const permission = await Notification.requestPermission();
        if (permission !== 'granted') {
          throw new Error('Permission not granted for Notification');
        }

        // Subscribe to push notifications
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: this.urlBase64ToUint8Array('BOeFxlwpuz260qhHCQA9Kics217p_n_PoweZwlTQa-WEzk7nObTJjyYW8Y9PBi4clasw8x7rFOxuCEjrZwGdfOg')
        });

        // Send subscription to the server
        await fetch('/api/subscribe', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(subscription),
        });

        console.log('User is subscribed:', subscription);
        this.isSubscribed = true;  // Update state to hide the button
      } catch (error) {
        console.error('Failed to subscribe the user: ', error);
      }
    },
    urlBase64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
    async checkSubscriptionStatus() {
      if ('serviceWorker' in navigator) {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.getSubscription();
        this.isSubscribed = !!subscription;  // Set to true if a subscription exists
      }
    }
  },
  mounted() {
    // Register the service worker on app mount
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch(error => {
          console.log('Service Worker registration failed:', error);
        });
    }

    // Check subscription status to update the button visibility
    this.checkSubscriptionStatus();
  }
};
</script>

<style>
/* Apply dark theme to the entire page */
body {
  margin: 0;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background-color: #121212; /* Dark background */
  color: #ffffff; /* Light text color */
}

#app {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column; /* Stack button and SeekBarDisplay vertically */
}

button {
  background-color: #1e88e5;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 20px; /* Add some space between the button and SeekBarDisplay */
}

button:hover {
  background-color: #1565c0;
}
</style>
