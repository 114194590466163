<template>
  <div class="seekbar-display">
    <div class="header">
      <span>Last Updated: {{ timeAgo }}</span>
    </div>
    <div class="seekbars">
      <div class="seekbar">
        <label>Mental Health: {{ seekBar1.value + 1 }}</label>
        <div class="progress-bar">
          <div
            class="progress-fill"
            :style="{
              width: `${(seekBar1.value / 9) * 100}%`,
              backgroundColor: getSeekBarColor(seekBar1.value)
            }"
          ></div>
        </div>
      </div>
      <div class="seekbar">
        <label>Physical Health: {{ seekBar2.value + 1 }}</label>
        <div class="progress-bar">
          <div
            class="progress-fill"
            :style="{
              width: `${(seekBar2.value / 9) * 100}%`,
              backgroundColor: getSeekBarColor(seekBar2.value)
            }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      seekBar1: { value: 0 },
      seekBar2: { value: 0 },
      lastUpdated: null,
      timeAgo: "",
      baseUrl : "https://mood.jk.sk"
      // baseUrl : "http://localhost:3000"
    };
  },
  mounted() {
    this.fetchSeekBarValues();
    this.setupPolling();
    this.updateTimeAgo(); // Initial call to update time ago
    setInterval(this.updateTimeAgo, 1000); // Update time ago every second
  },
  methods: {
    fetchSeekBarValues() {
      fetch(`${this.baseUrl}/api/getAllSeekbars`)
        .then(response => response.json())
        .then(data => {
          const seekBar1 = data.seekbars.find(sb => sb._id === 'SeekBar1');
          const seekBar2 = data.seekbars.find(sb => sb._id === 'SeekBar2');

          if (seekBar1) {
            this.seekBar1.value = seekBar1.value;
          }

          if (seekBar2) {
            this.seekBar2.value = seekBar2.value;
          }

          // Handle the timestamp, setting lastUpdated only if timestamp is not null
          if (data.timestamp) {
            this.lastUpdated = data.timestamp;
          } else {
            this.lastUpdated = null; // Reset if no valid timestamp
          }

          this.updateTimeAgo(); // Update time ago when data is fetched
        })
        .catch(error => console.error("Error fetching seekbar values:", error));
    },
    setupPolling() {
      setInterval(() => {
        this.fetchSeekBarValues();
      }, 5000); // Poll every 5 seconds
    },
    updateTimeAgo() {
      if (!this.lastUpdated) {
        this.timeAgo = "N/A";
        return;
      }
      const now = new Date();
      const updatedDate = new Date(this.lastUpdated);
      const seconds = Math.floor((now - updatedDate) / 1000);

      if (seconds < 60) {
        this.timeAgo = `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
      } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        this.timeAgo = `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
      } else if (seconds < 86400) {
        const hours = Math.floor(seconds / 3600);
        this.timeAgo = `${hours} hour${hours !== 1 ? 's' : ''} ago`;
      } else if (seconds < 604800) {
        const days = Math.floor(seconds / 86400);
        this.timeAgo = `${days} day${days !== 1 ? 's' : ''} ago`;
      } else if (seconds < 2592000) {
        const weeks = Math.floor(seconds / 604800);
        this.timeAgo = `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
      } else if (seconds < 31536000) {
        const months = Math.floor(seconds / 2592000);
        this.timeAgo = `${months} month${months !== 1 ? 's' : ''} ago`;
      } else {
        const years = Math.floor(seconds / 31536000);
        this.timeAgo = `${years} year${years !== 1 ? 's' : ''} ago`;
      }
    },
    getSeekBarColor(value) {
      if (value <= 4.5) {
        // Transition from green to yellow
        const green = 255;
        const red = Math.floor((value / 4.5) * 255);
        return `rgb(${red}, ${green}, 0)`;
      } else {
        // Transition from yellow to red
        const red = 255;
        const green = 255 - Math.floor(((value - 4.5) / 4.5) * 255);
        return `rgb(${red}, ${green}, 0)`;
      }
    }
  }
};
</script>

<style scoped>
.seekbar-display {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  background-color: #121212;
  color: #ffffff;
  border-radius: 10px;
}

.header {
  text-align: right;
  font-size: 14px;
  margin-bottom: 20px;
  color: #b0bec5;
}

.seekbars {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.seekbar {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

label {
  font-weight: bold;
  color: #b0bec5;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #37474f;
  border-radius: 5px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  border-radius: 5px;
  transition: width 0.5s ease, background-color 0.5s ease;
}
</style>
